// import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import ExceptionService from "./ExceptionService";
class EventService {
	async LogEvent(eventName: string, properties: { [key: string]: string }): Promise<void> {
		try {
			console.log(eventName, properties);
			// await FirebaseAnalytics.logEvent({
			// 	name: eventName,
			// 	params: properties,
			// });
		} catch (error) {
			// if (error != "Firebase analytics is not initialized. Make sure initializeFirebase() is called once") {
			// 	await ExceptionService.LogException(error)
			// 		.then(() => {
			// 			return;
			// 		})
			// 		.catch(() => {
			// 			return;
			// 		});
			// }
		}
	}
}

export default new EventService();
