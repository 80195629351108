import QuoteService from "../../services/QuoteService";
import { Quote } from "../../interfaces";
import { Commit } from "vuex";

// initial state
const state = {
	all: [],
	currentQuote: null,
};

// getters
const getters = {};

// actions
const actions = {
	async getAllQuotes({ commit }: { commit: Commit }) {
		const quotes = await QuoteService.getQuotes();
		commit("setQuotes", quotes);
	},
	async setCurrentQuote({ commit }: { commit: Commit }, quote: any) {
		commit("setCurrentQuote", quote);
	},
	async reset({ commit }: { commit: Commit }) {
		commit("reset");
	},
};

// mutations
const mutations = {
	setQuotes(state: any, quotes: Quote[]) {
		state.all = quotes;
	},
	setCurrentQuote(state: any, quote: Quote) {
		state.currentQuote = quote;
	},
	reset(state: any) {
		state.all = [];
		state.currentQuote = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
