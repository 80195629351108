import axios from "axios";
import { AuthHeader } from "./AuthHeader";
import { EnquirySwitcher } from "@/classes/enquiries/EnquirySwitcher";
import { computed } from "vue";
import store from "@/store";
import { Specialist } from "@/classes/enquiries/Specialist";
import ExceptionService from "./ExceptionService";
class EnquiryService {
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	private enquiryId = computed(() => store.getters["enquiries/currentEnquiryId"]);
	async getEnquiriesQuotesAndBookings(): Promise<EnquirySwitcher | undefined> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/enquiries/enquiry-switcher`, requestOptions)
			.then(async (response) => {
				return response.data as EnquirySwitcher;
			})
			.catch(async (error) => {
				await ExceptionService.LogException(error);
				return undefined;
			});
	}
	async getSpecialist(): Promise<Specialist | null> {
		const header = await AuthHeader();
		const requestOptions = {
			headers: header,
		};
		return await axios
			.get(`${this.apiUrl.value}/api/members/get-agent-of-enquiry/${this.enquiryId.value}`, requestOptions)
			.then(async (response) => {
				return response.data as Specialist;
			})
			.catch(async (error) => {
				await ExceptionService.LogException(error);
				return null;
			});
	}

	async setEnquiryAfterLogin(): Promise<void> {
		const enquiries = await this.getEnquiriesQuotesAndBookings();
		await store.dispatch("enquiries/setEnquirySwitchData", enquiries);
		if (enquiries != null && enquiries.currentEnquiries.length > 0) {
			// Active enquiry set this
			await store.dispatch("enquiries/setEnquiry", enquiries.currentEnquiries[0]);
		} else if (enquiries != null && enquiries.pastEnquiries.length > 0) {
			// Set the most recent past enquiry
			await store.dispatch("enquiries/setEnquiry", enquiries.pastEnquiries[0]);
		}
	}

	async loadEnquiryAfterNotification(enquiryId: string): Promise<void> {
		const enquiries = await this.getEnquiriesQuotesAndBookings();
		for (let i = 0; i < enquiries.currentEnquiries.length; i++) {
			if (enquiries.currentEnquiries[i].id == enquiryId) {
				await store.dispatch("enquiries/setEnquiry", enquiries.currentEnquiries[i]);
			}
		}
	}
}

export default new EnquiryService();
