import axios from "axios";
import { Preferences } from "@capacitor/preferences";
import store from "@/store";
import { LoginResponse } from "../interfaces/";
import { LoginCheckResponse } from "@/classes/login/LoginCheckResponse";
import HubService from "./HubService";
import EventService from "./EventService";
import { computed } from "vue";

import { AuthHeader } from "./AuthHeader";
class AuthService {
	private user = computed(() => store.state.auth.user);
	private apiUrl = computed(() => store.getters["session/apiUrl"]);
	async loginCheck(userName: string): Promise<LoginCheckResponse> {
		const response = await axios.post(`${process.env.VUE_APP_ADMINAPIURL}/api/app/login-check`, {
			userName: userName,
		});
		const data = response.data as LoginCheckResponse;
		await Preferences.set({
			key: "brands",
			value: JSON.stringify(data.brands),
		});
		return data;
	}

	async login(username: string, password: string, url: string): Promise<LoginResponse> {
		const response = await axios.post(`${url}/api/users/authenticate`, {
			email: username,
			password: password,
		});

		const userData = response.data as LoginResponse;
		await Preferences.set({
			key: "user",
			value: JSON.stringify(userData),
		});

		await EventService.LogEvent(`logging_in`, { username: userData.username });

		return userData;
	}

	async forgotPassword(username: string, url: string): Promise<boolean> {
		const response = await axios.post(`${url}/api/users/forgot-password`, {
			email: username,
			templateId: "cc4d2689-12af-4151-f755-08d5e71248e3",
		});

		const success = response.status === 200;
		return success;
	}

	async logout(): Promise<void> {
		if (this.user.value != null) {
			await EventService.LogEvent(`logging_out`, { username: this.user.value.username });
		}
		await store.dispatch("reset");
		await HubService.closeConnection();
	}

	async authCheck(): Promise<boolean> {
		const userData = await Preferences.get({ key: "user" });

		if (userData.value == null) return false;
		const user: LoginResponse = JSON.parse(userData.value ? userData.value : "");

		// read the jwt token and see if it has expired
		if (user != null && user.token != null) {
			const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
			if (jwtToken.exp < Date.now() / 1000) {
				// token has expired, request a new one
				return await axios
					.post(`${this.apiUrl.value}/api/users/refresh-token`, {
						token: user.token,
						refreshToken: user.refreshToken,
					})
					.then(async (response) => {
						const data = response.data as LoginResponse;
						await Preferences.set({
							key: "user",
							value: JSON.stringify(data),
						});
						return true;
					})
					.catch(async () => {
						return false;
					});
			}
		} else {
			const header = await AuthHeader();
			const requestOptions = {
				headers: header,
			};

			return await axios
				.get(`${this.apiUrl.value}/api/users/auth-check`, requestOptions)
				.then(async () => {
					return true;
				})
				.catch(() => {
					return false;
				});
		}
	}

	async tokenRefresh(): Promise<boolean> {
		const userData = await Preferences.get({ key: "user" });

		if (userData.value == null) return false;
		const user: LoginResponse = JSON.parse(userData.value ? userData.value : "");

		// read the jwt token and see if it has expired
		if (user != null && user.token != null) {
			const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
			if (jwtToken.exp < Date.now() / 1000) {
				// token has expired, request a new one
				return await axios
					.post(`${this.apiUrl.value}/api/users/refresh-token`, {
						token: user.token,
						refreshToken: user.refreshToken,
					})
					.then(async (response) => {
						const data = response.data as LoginResponse;
						await Preferences.set({
							key: "user",
							value: JSON.stringify(data),
						});
						return true;
					})
					.catch(async () => {
						return false;
					});
			}
		}
	}
}

export default new AuthService();
