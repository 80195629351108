import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { Preferences } from "@capacitor/preferences";
import store from "@/store";
import NavigationTabs from "../views/NavigationTabs.vue";
const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/tabs/home",
	},
	{
		path: "",
		redirect: "/tabs/home",
	},
	{
		name: "login",
		path: "/login",
		component: () => import("../views/LoginScreen.vue"),
	},
	{
		path: "/tabs",
		component: NavigationTabs,
		children: [
			{
				path: "",
				redirect: "/tabs/home",
			},
			{
				name: "home",
				path: "home",
				component: () => import("@/views/HomeScreen.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "debug",
				path: "debug",
				component: () => import("@/views/AdminDebug.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "switch-enquiry",
				path: "switch-enquiry",
				component: () => import("@/views/EnquirySwitcher.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "messages",
				path: "messages",
				component: () => import("@/views/AllMessages.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "profile",
				path: "profile",
				component: () => import("@/views/UserProfile.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "specialist",
				path: "specialist",
				component: () => import("@/views/SpecialistDetails.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "destination-guide",
				path: "destination-guide",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "things-to-do",
				path: "things-to-do",
				component: () => import("@/views/ThingsToDo.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quotes",
				path: "quotes",
				component: () => import("@/views/AllQuotes.vue"),
			},
			{
				name: "quote-view",
				path: "quote-view/:id",
				component: () => import("@/views/QuoteDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-destinations",
				path: "quote-destinations/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-itinerary",
				path: "quote-itinerary/:id",
				component: () => import("@/views/QuoteItinerary.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-map",
				path: "quote-map/:id",
				component: () => import("@/views/MapView.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "quote-feedback",
				path: "quote-feedback/:id",
				component: () => import("@/views/QuoteFeedback.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "bookings",
				path: "bookings",
				component: () => import("@/views/AllBookings.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-overview",
				path: "booking-overview/:id",
				component: () => import("@/views/BookingOverview.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-trip-planner",
				path: "booking-trip-planner/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-view",
				path: "booking-view/:id",
				component: () => import("@/views/BookingDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-things-to-do",
				path: "booking-things-to-do/:id",
				component: () => import("@/views/ThingsToDo.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-passengers",
				path: "booking-passengers/:id",
				component: () => import("@/views/BookingPassengers.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-destinations",
				path: "booking-destinations/:id",
				component: () => import("@/views/TripDestinations.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-documents",
				path: "booking-documents/:id",
				component: () => import("@/views/BookingDocuments.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-document-detail",
				path: "booking-document-detail/:id",
				component: () => import("@/views/DocumentDetail.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-itinerary",
				path: "booking-itinerary/:id",
				component: () => import("@/views/BookingItinerary.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				name: "booking-map",
				path: "booking-map/:id",
				component: () => import("@/views/MapView.vue"),
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
];
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: routes,
});
router.beforeEach(async (to, from, next) => {
	await (store as any).restored;
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		const userData = await Preferences.get({ key: "user" });
		if (userData.value == null) {
			next({
				path: "/login",
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
