<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom" color="medium">
				<ion-tab-button v-for="(tab, index) in tabs" :key="index" :tab="tab.title" :href="tab.url" :class="tab.isSelected ? 'tab-selected' : ''" layout="icon-top">
					<ion-icon :ios="tab.iosIcon" :md="tab.mdIcon"></ion-icon>
					<ion-label v-html="tab.title"></ion-label>
					<ion-badge v-if="tab.badgeCount > 0">{{ tab.badgeCount }}</ion-badge>
				</ion-tab-button>
			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script lang="ts">
	import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonBadge, IonRouterOutlet } from "@ionic/vue";
	import {
		arrowBackOutline,
		arrowBackSharp,
		bagOutline,
		bagSharp,
		chatbubblesOutline,
		chatbubblesSharp,
		repeatOutline,
		repeatSharp,
		mapOutline,
		mapSharp,
		globeOutline,
		globeSharp,
		imageOutline,
		imageSharp,
		listOutline,
		listSharp,
		ellipsisHorizontalOutline,
		ellipsisHorizontalSharp,
		documentOutline,
		documentSharp,
	} from "ionicons/icons";
	import { computed, ref, watch } from "vue";
	import { useRouter } from "vue-router";
	import { useStore } from "vuex";

	export default {
		name: "NavigationTabs",
		components: {
			IonBadge,
			IonLabel,
			IonTabs,
			IonTabBar,
			IonTabButton,
			IonIcon,
			IonPage,
			IonRouterOutlet,
		},
		setup() {
			const router = useRouter();
			const tabs = ref();
			const store = useStore();
			const currentRouteName = computed(() => router.currentRoute.value.name);
			const newMessageCount = computed(() => store.state.enquiries.newMessageCount);
			const newBookingCount = computed(() => store.state.bookings.newBookingCount);
			const newQuoteCount = computed(() => store.state.quotes.newQuoteCount);
			/*
			 * There are 4 different views
			 * 1. Home Page, Enquiry Switch Page,
			 * 	- Switch
			 * 	- Messages
			 * 	- Your Quotes
			 * 	- Your Bookings
			 * 	- Your Preferences
			 * 2. Messages Page, Quotes Page, Bookings Page, Your Specialist, Things to Do, /tabs/profile
			 * 	- Back to Enquiry (Home)
			 * 	- Messages
			 * 	- Your Quotes
			 * 	- Your Bookings
			 * 	- Your Preferences
			 * 3. Quote View, Itinerary Page, Destination Guide, Feedback
			 * 	- Back to Quotes
			 * 	- Quote Itinerary
			 * 	- Destiantion Guide
			 * 	- Feedback
			 * 	- Your Preferences
			 * * 3. Booking View, Itinerary Page, Destination Guide, Feedback
			 * 	- Back to Bookings
			 * 	- Booking Itinerary
			 * 	- Destiantion Guide
			 * 	- Feedback
			 * 	- Your Preferences
			 */

			const menuTypeOne = ["home"];
			const menuTypeTwo = ["messages", "quotes", "bookings", "specialist", "things-to-do", "profile", "switch-enquiry", "destination-guide", "things-to-do", "debug"];
			const menuTypeThree = ["quote-view", "quote-destinations", "quote-itinerary", "quote-map", "quote-feedback"];
			const menuTypeFour = ["booking-overview", "booking-trip-planner", "booking-view", "booking-things-to-do", "booking-passengers", "booking-destinations", "booking-documents", "booking-document-detail", "booking-itinerary", "booking-map"];

			const setupTabs = () => {
				tabs.value = [];
				var routeName = currentRouteName.value.toString() ?? "";
				if (menuTypeOne.includes(routeName)) {
					tabs.value.push({
						title: "Switch<br />Enquiries",
						url: router.resolve({ name: "switch-enquiry" }).href,
						iosIcon: repeatOutline,
						mdIcon: repeatSharp,
						badgeCount: 0,
					});
					tabs.value.push({
						title: "Messages<br />History",
						url: router.resolve({ name: "messages" }).href,
						iosIcon: chatbubblesOutline,
						mdIcon: chatbubblesSharp,
						badgeCount: newMessageCount.value,
					});
					tabs.value.push({
						title: "Your<br />Quotes",
						url: router.resolve({ name: "quotes" }).href,
						iosIcon: mapOutline,
						mdIcon: mapSharp,
						badgeCount: newQuoteCount.value,
					});
					tabs.value.push({
						title: "Your<br />Bookings",
						url: router.resolve({ name: "bookings" }).href,
						iosIcon: bagOutline,
						mdIcon: bagSharp,
						badgeCount: newBookingCount.value,
					});
				}
				if (menuTypeTwo.includes(routeName)) {
					tabs.value.push({
						title: "Back to<br />Enquiry",
						url: "/tabs/home",
						iosIcon: arrowBackOutline,
						mdIcon: arrowBackSharp,
						badgeCount: 0,
					});
					tabs.value.push({
						title: "Messages<br />History",
						url: "/tabs/messages",
						iosIcon: chatbubblesOutline,
						mdIcon: chatbubblesSharp,
						badgeCount: newMessageCount.value,
					});
					tabs.value.push({
						title: "Your<br />Quotes",
						url: "/tabs/quotes",
						iosIcon: mapOutline,
						mdIcon: mapSharp,
						badgeCount: newQuoteCount.value,
						isSelected: currentRouteName.value == "quotes",
					});
					tabs.value.push({
						title: "Your<br />Bookings",
						url: "/tabs/bookings",
						iosIcon: bagOutline,
						mdIcon: bagSharp,
						badgeCount: newBookingCount.value,
						isSelected: currentRouteName.value == "bookings",
					});
				}

				if (menuTypeThree.includes(routeName)) {
					const routeIdParam = router.currentRoute.value.params.id;

					if (currentRouteName.value == "quote-view") {
						tabs.value.push({
							title: "Back to<br />Quotes",
							url: router.resolve({ name: "quotes" }).href,
							iosIcon: arrowBackOutline,
							mdIcon: arrowBackSharp,
							badgeCount: newQuoteCount.value,
							isSelected: false,
						});
					} else {
						tabs.value.push({
							title: "Full<br />Quote",
							url: router.resolve({ name: "quote-view", params: { id: routeIdParam } }).href,
							iosIcon: globeOutline,
							mdIcon: globeSharp,
							badgeCount: 0,
							isSelected: false,
						});
					}

					tabs.value.push({
						title: "Quote<br />Itinerary",
						url: router.resolve({ name: "quote-itinerary", params: { id: routeIdParam } }).href,
						iosIcon: listOutline,
						mdIcon: listSharp,
						badgeCount: 0,
						isSelected: currentRouteName.value == "quote-itinerary",
					});
					tabs.value.push({
						title: "Itinerary<br />Map",
						url: router.resolve({ name: "quote-map", params: { id: routeIdParam } }).href,
						iosIcon: mapOutline,
						mdIcon: mapSharp,
						badgeCount: 0,
						isSelected: currentRouteName.value == "quote-map",
					});
					tabs.value.push({
						title: "Destination<br />Guide",
						url: router.resolve({ name: "quote-destinations", params: { id: routeIdParam } }).href,
						iosIcon: imageOutline,
						mdIcon: imageSharp,
						badgeCount: 0,
						isSelected: currentRouteName.value == "quote-destinations",
					});
					tabs.value.push({
						title: "Quote<br />Feedback",
						url: router.resolve({ name: "quote-feedback", params: { id: routeIdParam } }).href,
						iosIcon: chatbubblesOutline,
						mdIcon: chatbubblesSharp,
						badgeCount: 0,
						isSelected: currentRouteName.value == "quote-feedback",
					});
				}

				if (menuTypeFour.includes(routeName)) {
					const routeIdParam = router.currentRoute.value.params.id;
					if (currentRouteName.value == "booking-view") {
						tabs.value.push({
							title: "Back to<br />Bookings",
							url: router.resolve({ name: "bookings" }).href,
							iosIcon: arrowBackOutline,
							mdIcon: arrowBackSharp,
							badgeCount: newBookingCount.value,
							isSelected: false,
						});
					} else {
						tabs.value.push({
							title: "Full<br />Booking",
							url: router.resolve({ name: "booking-view", params: { id: routeIdParam } }).href,
							iosIcon: globeOutline,
							mdIcon: globeSharp,
							badgeCount: 0,
							isSelected: false,
						});
					}

					tabs.value.push({
						title: "Booking<br />Itinerary",
						url: router.resolve({ name: "booking-itinerary", params: { id: routeIdParam } }).href,
						iosIcon: listOutline,
						mdIcon: listSharp,
						badgeCount: 0,
					});
					tabs.value.push({
						title: "Destination<br />Guide",
						url: router.resolve({ name: "booking-destinations", params: { id: routeIdParam } }).href,
						iosIcon: imageOutline,
						mdIcon: imageSharp,
						badgeCount: 0,
					});
					tabs.value.push({
						title: "Itinerary<br />Map",
						url: router.resolve({ name: "booking-map", params: { id: routeIdParam } }).href,
						iosIcon: mapOutline,
						mdIcon: mapSharp,
						badgeCount: 0,
					});
					tabs.value.push({
						title: "Booking<br />Documents",
						url: router.resolve({ name: "booking-documents", params: { id: routeIdParam } }).href,
						iosIcon: documentOutline,
						mdIcon: documentSharp,
						badgeCount: 0,
					});
				}

				tabs.value.push({
					title: "Your<br />Preferences",
					url: "/tabs/profile",
					iosIcon: ellipsisHorizontalOutline,
					mdIcon: ellipsisHorizontalSharp,
					badgeCount: 0,
				});
			};

			watch(currentRouteName, async () => {
				setupTabs();
			});
			watch(newMessageCount, async () => {
				setupTabs();
			});
			watch(newBookingCount, async () => {
				setupTabs();
			});
			watch(newQuoteCount, async () => {
				setupTabs();
			});

			setupTabs();

			return {
				tabs,
			};
		},
	};
</script>